<template>
  <div id="ChargerFinder" class="container-fluid container-lg pl-4 pt-3">
    <div class="row">
      <div class="col-12 col-md-5 mb-4 mb-md-0">
        <div class="row">
          <h1 class="col">Find chargers in your area</h1>
        </div>
        <form class="form-row needs-validation" @submit.prevent="updateZIP" novalidate>
          <div class="col form-group">
            <label for="ZipInput" class="mb-n1">Search by zip</label>
            <input id="ZipInput" v-model="zipSearch" type="text" class="form-control form-control-sm"
              :class="{ 'is-invalid': zipInvalid }" placeholder="ZIP Code" required />
            <div class="invalid-feedback">Please provide a valid ZIP.</div>
          </div>
        </form>
        <div class="row">
          <div class="col">
            <h3>Chargers found for:</h3>
            <div>{{ selectedVehicleYMMS }}
              <div class="vehicle-links inline">
                <span class="clickable" @click="$bvModal.show('ChargingMapYMMSSelector')">change</span>
                |
                <span class="clickable" @click="selectNone">select none</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <router-link class="my-1" v-if="selectedVehicle" :to="`/detail/${selectedVehicle.YMMS_Desc}`"
              custom v-slot="{ navigate }">
              <button @click="navigate" class="btn button" role="link">
                learn more &raquo;
              </button>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span class="disclaimer">charging availability may change depending on selected
              vehicle</span>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <div class="form-check">
              <input id="DCFastCheck" class="form-check-input" type="checkbox" v-model="showDCFast" />
              <label for="DCFastCheck" class="form-check-label">
                Show DC Fast charging stations:
                <span class="charger-count">{{ dcFastStationCount }} found</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check">
              <input id="Level2Check" class="form-check-input" type="checkbox" v-model="showLevel2" />
              <label for="Level2Check" class="form-check-label">
                Show level 2 charging stations:
                <span class="charger-count">{{ level2StationCount }} found</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <div v-show="loadingMap" class="loading-overlay">
          <div class="d-flex justify-content-center loading-spinner">
            <div class="spinner-border text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <ChargingStationMap @change="mapChanged" @idle="mapIdle" @zoomed="handleZoomed" :showDCFast="showDCFast"
          :showLevel2="showLevel2" :radius="searchRadius" />
      </div>
    </div>
    <b-modal id="ChargingMapYMMSSelector" centered title="Choose a Specific EV" hide-footer>
      <div class="row">
        <div class="col">
          <VehicleYMMSSelector v-model="ymmsSelectorVehicle" />
        </div>
      </div>
      <div class="row">
        <div class="col text-right">
          <button class="btn button" @click="selectVehicle">
            select <span class="accent">&raquo;</span>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
const ChargingStationMap = () => import("Components/ChargingStationMap");
const VehicleYMMSSelector = () => import("Components/VehicleYMMSSelector");
export default {
  props: ["zip", "ymms", "lat", "lng", "radius"],
  metaInfo: {
    title: "Find Electric Vehicle Chargers Near You",
    meta: [
      {
        name: "description",
        content:
          "Looking for electric vehicle chargers near you? Check out Electric Driver for a convenient and reliable charging experience. Find a charging station now.",
      },
    ],
  },
  data() {
    return {
      zipSearch: this.zip || this.$store.state.searchOptions.zip,
      ymmsSelectorVehicle: null,
      selectedVehicle: null,
      mapCenter: {
        lat: this.lat,
        lng: this.lng,
      },
      isMapChanged: false,
      showLevel2: false,
      showDCFast: true,
      searchRadius: Number(this.radius) || 50,
      zipInvalid: false,
      loadingMap: false
    };
  },
  async mounted() {
    this.$store.commit("loading", "Getting charging stations...");
    await this.$store.dispatch("fetchAllYMMS");
    this.selectedVehicle = this.$store.state.allYmms.find(
      (x) => x.YMMS_Desc == this.ymms
    );
    await this.updateChargingStations(this.zip, this.ymms, this.lat, this.lng)
    this.mapCenter = {
      lat: this.$store.state.chargingStationInfo?.location.lat,
      lng: this.$store.state.chargingStationInfo?.location.lng,
    };

    this.$store.commit("loaded");
  },
  methods: {
    updateZIP() {
      this.zipInvalid = false;
      if (
        this.zipSearch &&
        this.zipSearch.length === 5 &&
        !isNaN(this.zipSearch)
      ) {
        this.inputChanged();
      } else {
        this.zipInvalid = true;
      }
    },
    handleZoomed($event) {
      let radius = 50;
      if ($event <= 9) {
        radius = 50;
      } else if ($event > 11) {
        radius = 5;
      } else {
        radius = Math.floor(50 / (8.5 - $event) ** 2);
      }
      this.searchRadius = radius;
    },
    mapChanged($event) {
      if (
        this.mapCenter.lat == $event.lat &&
        this.mapCenter.lng == $event.lng
      ) {
        return;
      }
      this.mapCenter = $event;
      this.isMapChanged = true;
    },
    mapIdle() {
      this.inputChanged();
    },
    selectNone() {
      if (this.selectedVehicle) {
        this.selectedVehicle = null;
        this.inputChanged();
      }
    },
    selectVehicle() {
      this.$bvModal.hide("ChargingMapYMMSSelector");
      if (
        this.selectedVehicle?.YMMS_Desc == this.ymmsSelectorVehicle.YMMS_Desc
      ) {
        return;
      }
      this.selectedVehicle = { ...this.ymmsSelectorVehicle };
      this.ymmsSelectorVehicle = null;
      this.inputChanged();
    },
    async inputChanged() {
      const newQuery = {
        zip: this.zipSearch,
        ymms: this.selectedVehicle?.YMMS_Desc,
        lat: this.mapCenter?.lat?.toString(),
        lng: this.mapCenter?.lng?.toString(),
        radius: this.searchRadius?.toString(),
      };

      const currentQuery = this.$route.query;

      if (
        JSON.stringify(newQuery) !== JSON.stringify(currentQuery)
      ) {
        if (newQuery.zip !== currentQuery.zip) {
          newQuery.lat = null;
          newQuery.lng = null;
        }
        try {
          await this.$router.replace({
            name: "chargerFinder",
            query: newQuery,
          });
        } catch (error) {
          console.error(error);
        }
      }
    },
    async updateChargingStations(zip, ymms, lat, lng) {
      this.loadingMap = true;
      if (lat && lng) {
        try {
          await this.$store.dispatch("fetchChargingStationsByCoords", {
            ymms,
            lat,
            lng,
            radius: this.searchRadius,
          });
        } catch (error) {
          this.loadingMap = false;
          console.error(error);
        }
      } else {
        try {
          await this.$store.dispatch("fetchChargingStationsByZip", {
            ymms,
            zip,
            radius: this.searchRadius,
          });
        } catch (error) {
          this.loadingMap = false;
          console.error(error);
        }
      }
      this.loadingMap = false;
    }
  },
  computed: {
    inputs() {
      return this.$route.query;
    },
    selectedVehicleYMMS() {
      return this.selectedVehicle
        ? `${this.selectedVehicle.Year} ${this.selectedVehicle.Make} ${this.selectedVehicle.Model} ${this.selectedVehicle.Trim}`
        : "No vehicle selected";
    },
    dcFastStationCount() {
      const locations =
        this.$store.getters.filterChargingLocations("dc_fast") || [];
      return locations.length;
    },
    level2StationCount() {
      const locations =
        this.$store.getters.filterChargingLocations("level2") || [];
      return locations.length;
    },
  },
  components: { ChargingStationMap, VehicleYMMSSelector },
  watch: {
    inputs() {
      this.updateChargingStations(this.zip, this.ymms, this.lat, this.lng);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/forms";

#ChargerFinder {
  color: black;
  font-size: 0.875rem;
  top: 10px;
  position: relative;
  border-top: 11px solid #0b1116;
  background-color: white;
}

h1,
h3 {
  color: $header-font-color;
  font-weight: 800;
}

h1 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 3px;
}

.vehicle-links {
  font-size: 0.685rem;
}

.disclaimer {
  color: rgba(47, 79, 79, 0.61);
}

.form-check {
  input[type="checkbox"] {
    background: white;
    accent-color: #797979;
  }

  color: #797979;

  .charger-count {
    color: black;
    font-weight: bold;
  }
}

label {
  color: #333333;
}

.ymms-selector {
  select {
    @extend .form-control, .form-control-sm;
    margin-bottom: 25px;
    width: 100% !important;
  }

  option {
    padding-top: 20px;
  }
}

.btn {
  font-size: 0.8rem;
  font-weight: normal;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.loading-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;

  .loading-spinner {
    top: 50%;
    left: 50%;
    position: absolute;
    margin: 0;
    transform: translate(-50%, -50%);
  }
}
</style>
